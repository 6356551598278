@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/maison/fonnts.com-Maison_Neue_Light.ttf') format('truetype'),
         url('../fonts/maison/fonnts.com-Maison_Neue_Mono.ttf') format('truetype'),
         url('../fonts/maison/fonnts.com-Maison_Neue_Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/maison/fonnts.com-Maison_Neue_Bold.ttf') format('truetype'),
         url('../fonts/maison/fonnts.com-Maison_Neue_Book.ttf') format('truetype'),
         url('../fonts/maison/fonnts.com-Maison_Neue_Light.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helvetica/helvetica-light-587ebe5a59211.ttf') format('truetype'),
         url('../fonts/helvetica/Helvetica-Oblique.ttf') format('truetype'),
         url('../fonts/helvetica/Helvetica.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Helvetica';
    src: url('../fonts/helvetica/Helvetica-Bold.ttf') format('truetype'),
         url('../fonts/helvetica/Helvetica-BoldOblique.ttf') format('truetype'),
         url('../fonts/helvetica/helvetica-rounded-bold-5871d05ead8de.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Black.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-Condensed.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-CondensedItalic.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-Light.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-LightItalic.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url('../fonts/roboto/Roboto-Bold.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-BoldCondensed.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-BoldCondensedItalic.ttf') format('truetype'),
         url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
