@media (max-width: 1300px) {}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
    .hero_area {
        height: auto;
    }

    .header_section .main_nav {
        flex: 1;
    }

    #navbarSupportedContent {
        margin-top: 15px;
    }

    .slider_section {
        padding-top: 45px;
    }

    .feature_section .feature_container .box {
        padding: 30px 15px;
    }

    .feature_section .feature_container .box .img-box {
        width: 75px;
    }


    .feature_section {
        transform: none;
        padding: 90px 0;
    }


}

@media (max-width: 767px) {
    .layout_padding {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .layout_padding-top {
        padding-top: 90px;
    }

    .layout_padding-bottom {
        padding-bottom: 90px;
    }

    .slider_section .detail-box {
        padding-bottom: 45px;
    }

    .feature_section .feature_container {
        flex-direction: column;
    }

    .feature_section .feature_container .box {
        padding: 30px 15px;
    }

    .feature_section .feature_container .box:not(:nth-last-child(1)) {
        margin-bottom: 15px;
    }

    .about_section .detail-box {
        margin-bottom: 45px;
    }


    .professional_section .img-box {
        display: none;
    }

    .contact_section form {
        margin-bottom: 45px;
    }

    .info_section .info_items::before {
        width: 1px;
        height: 90%;
        top: 25px;
    }

}

@media (max-width: 576px) {}

@media (max-width: 480px) {}

@media (max-width: 400px) {}

@media (max-width: 360px) {}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

}